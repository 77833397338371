import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import IndexLayout from "@/layouts";
import ProtectedRoute from "./ProtectedRoute";

const Home = lazy(() => import("@/pages/Home"));

const Login = lazy(() => import("@/pages/auth/Login"));

const JobsView = lazy(() => import("@/pages/jobs/JobsView"));
const JobDetails = lazy(() => import("@/pages/jobs/JobDetails"));

const ApplicationsView = lazy(
  () => import("@/pages/job-applications/ApplicationsView"),
);
const ApplicationDetails = lazy(
  () => import("@/pages/job-applications/ApplicationDetails"),
);

const SharedJobs = lazy(() => import("@/pages/shared-jobs/SharedJobs"));
const SharedJobDetails = lazy(
  () => import("@/pages/shared-jobs/SharedJobDetails"),
);

const SavedJobs = lazy(() => import("@/pages/saved-jobs/SavedJobs"));

const AppliedList = lazy(() => import("@/pages/applied-list/AppliedList"));

const CvList = lazy(() => import("@/pages/cv-list/CvList"));
const CvDetails = lazy(() => import("@/pages/cv-list/CvDetails"));
const FormDetails = lazy(() => import("@/pages/cv-list/FormDetails"));
const EditCv = lazy(() => import("@/pages/cv-list/EditCv"));
const CreateCv = lazy(() => import("@/pages/cv-list/CreateCv"));

const EditJob = lazy(() => import("@/pages/jobs/EditJob"));
const Create = lazy(() => import("@/pages/jobs/Create"));

import LazyLoader from "@/components/ui/lazy-loader";

const router = createBrowserRouter([
  {
    path: "login",
    element: <LazyLoader children={<Login />} />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <IndexLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <LazyLoader children={<Home />} />,
      },
      {
        path: "jobs",
        element: <LazyLoader children={<JobsView />} />,
      },
      {
        path: "jobs/create",
        element: <LazyLoader children={<Create />} />,
      },
      {
        path: "jobs/:id",
        element: <LazyLoader children={<JobDetails />} />,
      },
      {
        path: "jobs/edit/:id",
        element: <LazyLoader children={<EditJob />} />,
      },
      {
        path: "job-applications",
        element: <LazyLoader children={<ApplicationsView />} />,
      },
      {
        path: "job-applications/:id",
        element: <LazyLoader children={<ApplicationDetails />} />,
      },
      {
        path: "shared-jobs",
        element: <LazyLoader children={<SharedJobs />} />,
      },
      {
        path: "shared-jobs/:id",
        element: <LazyLoader children={<SharedJobDetails />} />,
      },
      {
        path: "saved-jobs",
        element: <LazyLoader children={<SavedJobs />} />,
      },
      {
        path: "applied-list",
        element: <LazyLoader children={<AppliedList />} />,
      },
      {
        path: "cv-list",
        element: <LazyLoader children={<CvList />} />,
      },
      {
        path: "cv-list/:id/cv-forms/create",
        element: <LazyLoader children={<CreateCv />} />,
      },
      {
        path: "cv-list/:id",
        element: <LazyLoader children={<CvDetails />} />,
      },
      {
        path: "cv-list/:id/cv-forms/:cv",
        element: <LazyLoader children={<EditCv />} />,
      },
      {
        path: "cv-list/:id/cv-forms/:cv/details",
        element: <LazyLoader children={<FormDetails />} />,
      },
      {
        path: "contact/:id",
        element: (
          <ProtectedRoute>
            <div>Contact</div>
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
